import { ApolloQueryResult } from "@apollo/client";
import rootSetters from "../actions";
import rootGetters from "../getters";
import * as queryTypes from "./graphqlTypes";
export const validateContactForm =
  () => (dispatch: DispatchType, getState: GetStateType) => {
    const {
      form: { setForm },
    } = rootSetters(dispatch);

    setForm("contact.blur", {
      name: true,
      email: true,
      phone: true,
      subject: true,
      message: true,
    });

    const {
      form: { getContactFormMessage },
    } = rootGetters(getState());
    const messages = getContactFormMessage();
    return !Object.values(messages).some((message) => message !== "");
  };

export const createContact =
  () => (dispatch: DispatchType, getState: GetStateType) =>
    new Promise<any>((resolve, reject) => {
      const {
        form: { getForm },
      } = rootGetters(getState());
      const {
        graphql: { MUTATE },
      } = rootSetters(dispatch);

      MUTATE<CreateContactMutation>({
        mutation: queryTypes.CREATE_CONTACT,
        variables: {
          createContactInput: {
            origin: "WebSiteEasy",
            ...getForm("contact.data"),
          },
        },
      })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

const formActionCreators = (dispatch: <T>(action: any) => T) =>
  Object.assign(
    {},
    {
      validateContactForm: () => dispatch<boolean>(validateContactForm()),
      createContact: () =>
        dispatch<Promise<ApolloQueryResult<CreateContactMutation>>>(
          createContact()
        ),
    }
  );

export default formActionCreators;
