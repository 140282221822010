import { CSSProperties } from "react";
import styled, { css } from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;
type ModalContentProps = {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
};
export const ModalContent = styled.div<ModalContentProps>`
  ${({ height = "80vh", width = "60vw" }) => css`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: ${height};
    max-height: calc(100vh - 50px);
    min-width: ${width};
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 0;
`;

export const ModalTitle = styled.h2`
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  font-size: 28px;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
`;
