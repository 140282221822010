import { Action, createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./actionTypes";

export const setForm = createAction<{
  field: FlattenKeys<FormState>;
  value: any;
}>(actionTypes.SET_FORM);

const formActions = (dispatch: <T>(action: Action) => T) =>
  Object.assign(
    {},
    {
      setForm: <T extends FlattenKeys<FormState>>(
        field: T,
        value: DeepType<FormState, T>
      ) => dispatch<void>(setForm({ field, value })),
    }
  );

export default formActions;
