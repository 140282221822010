import { createReducer } from "@reduxjs/toolkit";
import { initialFormState as initialState } from "./state";
import * as actions from "./action";
import { setNestedField } from "../utils";

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actions.setForm, (state, { payload: { field, value } }) => {
      setNestedField(state, field, value);
    })
});
