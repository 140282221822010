import { gql } from "@apollo/client";

export const CREATE_CONTACT = gql`
  mutation createContact($createContactInput: CreateContactInput!) {
    createContact(createContactInput: $createContactInput) {
      id
      origin
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
