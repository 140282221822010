export function getNestedField<T extends object, K extends string>(
  obj: T,
  keys: K
): DeepType<T, K> {
  let result = obj as any;
  const keyList = keys.split(".");
  for (let i = 0; i < keyList.length; i++) {
    const key = keyList[i];
    result = result[key];
    if (result === undefined) {
      result = "";
      break;
    }
  }
  return result;
}

export function setNestedField<
  T extends object,
  K extends string,
  V extends DeepType<T, K>
>(obj: T, keys: K, value: V): T {
  const keysArray = keys.split(".");
  const lastIndex = keysArray.length - 1;
  let current: any = obj;
  for (let i = 0; i < lastIndex; i++) {
    const key = keysArray[i];
    if (!(key in current) || typeof current[key] !== "object")
      current[key] = /^\d/.test(keysArray[i + 1]) ? [] : {};
    current = current[key];
  }
  current[keysArray[lastIndex]] = value;
  return current;
}

export const toSection = (sectionId: string) => () => {
  const aboutElement = document.getElementById(`${sectionId}-section`);
  if (aboutElement) {
    const targetOffsetTop =
      aboutElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ top: targetOffsetTop, behavior: "smooth" });
  }
};
