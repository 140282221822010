import React, {
  ChangeEvent,
  Fragment,
  createRef,
  useEffect,
  useState,
} from "react";
import { ForwardedRef, InputHTMLAttributes } from "react";
import * as S from "./Styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ForwardedRef<HTMLInputElement>;
}

export interface InputWrapperProps {
  $activeLabelColor: string;
  $backgroundColor: string;
  $fontSize: number;
  $fontColor: string;
  $borderWidth: number;
  $borderRadius: number;
  $borderColor: string;
  $isFocused: boolean;
  $inputPaddingLeft: number;
  $inputHeight: number;
  $labelPadding: number;
  $labelLeft: number;
  $labelScale: number;
  $transitionTime: number;
  $labelWidth: number;
  $inputWidth: number;
  $hasError: boolean;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ref?: ForwardedRef<HTMLInputElement>;
  errorMessage?: boolean;
  label?: string;
  activeLabelColor?: string;
  backgroundColor?: string;
  fontSize?: number;
  fontColor?: string;
  borderWidth?: number;
  borderRadius?: number;
  borderColor?: string;
  labelScale?: number;
  labelLeft?: number;
  labelPadding?: number;
  lineLabel?: string;
  IconRight?: JSX.Element;
  transitionTime?: number;
  error?: string | boolean;
  mask?: (value: any) => any;
  unmask?: (value: any) => any;
  onDebouncedChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onFocus,
      onBlur,
      onChange,
      label = "",
      lineLabel = label,
      fontSize = 20,
      fontColor = "#364856",
      activeLabelColor = fontColor,
      backgroundColor = "white",
      borderColor = "transparent",
      borderWidth = 1,
      labelScale = 0.9,
      labelLeft = 10,
      labelPadding = 3,
      transitionTime = 300,
      borderRadius = 10,
      IconRight = <Fragment />,
      value: externalValue,
      children,
      error = "",
      errorMessage = true,
      mask = (value) => value,
      unmask = (value) => value,
      onDebouncedChange,
      className,
      placeholder,
      ...rest
    }: InputProps,
    ref
  ) => {
    const localInputRef = createRef<HTMLInputElement>();
    const inputRef = (ref ??
      localInputRef) as React.RefObject<HTMLInputElement>;
    const frameRef = createRef<HTMLLabelElement>();
    const [inputHeight, setInputHeight] = useState(67);
    const [inputWidth, setInputWidth] = useState(0);
    const [labelWidth, setlabelWidth] = useState(0);
    const [inputPaddingLeft, setInputPaddingLeft] = useState(20);
    const [value, setValue] = useState(mask(externalValue ?? ""));
    const [isFocused, setIsFocused] = useState(!!externalValue ?? false);
    const hasInputValue = `${value}`.length !== 0;
    const [childrenMouseEnter, setChildrenMouseEnter] = useState(false);
    const [iconRightHover, setIconRightHover] = useState(false);
    const [lastError, setLastError] = useState<string | boolean>("");
    const hasError = !!error;
    const [inputFocus, setInputFocus] = useState(false);
    const [inputPlaceholder, setInputPlaceholder] = useState(placeholder ?? "");
    const [transition, setTransition] = useState(0);

    React.useEffect(() => {
      const handleTransition = () => {
        setTimeout(() => {
          setTransition(transitionTime);
        }, transitionTime ?? 300);
      };
      handleTransition();
      window.addEventListener("resize", handleTransition);
      return () => {
        window.removeEventListener("resize", handleTransition);
      };
    }, []);

    useEffect(() => {
      setValue(externalValue);
    }, [externalValue]);

    useEffect(() => {
      if (frameRef.current) {
        setlabelWidth(frameRef.current.offsetWidth);
      }
    }, [frameRef?.current?.offsetWidth]);

    useEffect(() => {
      if (error) {
        setLastError(error);
      }
    }, [error]);

    useEffect(() => {
      if (hasInputValue) {
        setIsFocused(true);
      }
    }, [hasInputValue]);

    useEffect(() => {
      if (inputRef?.current) {
        setInputHeight(inputRef.current.offsetHeight);
        setInputWidth(inputRef.current.offsetWidth);
        const iStyle = getComputedStyle(inputRef.current);
        setInputPaddingLeft(+iStyle.paddingLeft.replace("px", ""));
      }
    }, []);

    useEffect(() => {
      setInputPlaceholder(placeholder as any);
    }, [placeholder]);

    useEffect(() => {
      if (
        ["", null, undefined].includes(externalValue as any) &&
        !inputPlaceholder &&
        !inputFocus
      )
        setIsFocused(false);
    }, [value, inputFocus, inputPlaceholder]);

    useEffect(() => {
      externalValue && setValue(externalValue);
      if (externalValue === "" && !inputFocus) setIsFocused(false);
    }, [externalValue]);

    let timerId: NodeJS.Timeout;

    useEffect(() => {
      const handleInputChange = () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          onDebouncedChange &&
            onDebouncedChange({
              target: { value: unmask(value) },
              currentTarget: { value: unmask(value) },
            } as ChangeEvent<HTMLInputElement>);
        }, 500);
      };
      handleInputChange();
      return () => {
        clearTimeout(timerId);
      };
    }, [value]);

    const handleFocus = () => {
      setInputFocus(true);
      setIsFocused(true);
    };

    const handleBlur = () => {
      setInputFocus(false);
      if (
        !hasInputValue &&
        !childrenMouseEnter &&
        !iconRightHover &&
        !inputPlaceholder
      ) {
        setIsFocused(false);
      }
    };

    const handleLabelFocus = () => {
      if (!isFocused) {
        setIsFocused(true);
        inputRef.current?.focus();
      }
    };

    const lb = isFocused ? lineLabel : label;
    const styles: InputWrapperProps = {
      $fontColor: fontColor,
      $backgroundColor: backgroundColor,
      $fontSize: fontSize,
      $borderWidth: borderWidth,
      $borderRadius: borderRadius,
      $borderColor: borderColor,
      $isFocused: isFocused,
      $inputPaddingLeft: inputPaddingLeft,
      $inputHeight: inputHeight,
      $labelPadding: labelPadding,
      $labelLeft: labelLeft,
      $labelScale: labelScale,
      $transitionTime: transition,
      $labelWidth: labelWidth,
      $hasError: hasError,
      $activeLabelColor: activeLabelColor,
      $inputWidth: inputWidth,
    };
    return (
      <S.AppWrapper className={className}>
        <S.Field>
          <S.Label onClick={handleLabelFocus} {...styles}>
            {lb}
          </S.Label>
          <S.InputWrapper {...styles}>
            <S.Input
              ref={inputRef as React.RefObject<HTMLInputElement>}
              type="text"
              placeholder={inputPlaceholder}
              onFocus={(e) => {
                handleFocus();
                onFocus && onFocus(e);
              }}
              onBlur={(e) => {
                handleBlur();
                onBlur && onBlur(e);
              }}
              onChange={(e) => {
                setValue(e.target.value);
                onChange &&
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: unmask(e.target.value),
                    },
                  });
              }}
              value={mask(value)}
              {...styles}
              {...rest}
            />
            <div
              onMouseEnter={() => {
                setIconRightHover(true);
              }}
              onMouseLeave={() => {
                setIconRightHover(false);
              }}
            >
              {IconRight}
            </div>
          </S.InputWrapper>
          {children && (
            <div
              onMouseEnter={() => {
                setChildrenMouseEnter(true);
              }}
              onMouseLeave={() => {
                setChildrenMouseEnter(false);
              }}
            >
              {children}
            </div>
          )}
        </S.Field>
        {errorMessage && <S.Error {...styles}>{lastError}</S.Error>}
      </S.AppWrapper>
    );
  }
);
InputComponent.displayName = "Input";

export default Object.assign(InputComponent, {});
