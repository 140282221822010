import styled from "styled-components";

export const CustomersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #364856;
  margin-bottom: 100px;
`;

export const CustomerBody = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

export const CustomerImageContainer = styled.div`
  display: flex;
  svg {
    max-width: 20vw;
    height: auto;
  }
`;

export const CustomersTitle = styled.h1`
  font-size: 65px;
  margin-block-end: 0;
`;

export const CustomersSubtitle = styled.h2`
  font-size: 22px;
  margin-block-end: 3em;
`;
