import * as S from "./Styles";
interface SolutionCardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: React.ReactNode;
  body: string;
  link: string;
}

const SolutionCard = ({ title, image, body, link }: SolutionCardProps) => {
  const redirect = () => {
    window.open(link, "_self");
  };
  return (
    <S.SolutionCard>
      <S.SolutionCardImageContainer onClick={redirect}>
        {image}
      </S.SolutionCardImageContainer>
      <S.SolutionCardTitle>{title}</S.SolutionCardTitle>
      <S.SolutionCardBody>{body}</S.SolutionCardBody>
    </S.SolutionCard>
  );
};

export default SolutionCard;
