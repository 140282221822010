export const initialFormState: FormState = {
  contact: {
    data: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    blur: {
      name: false,
      email: false,
      phone: false,
      subject: false,
      message: false,
    },
  },
  contactModal: false,
  contactSuccessModal: false,
  privacyPolicyModal: false
};
