/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import rootGetters from "../../store/getters";
import {
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from "@apollo/client";
import { QUERY, MUTATE } from "../../store/graphql/actionCreators";
import rootActions from "../../store/actions";
type ModuleTypes = "form";

export default function useStore() {
  const dispatch = useDispatch() as any;
  const importModules = (state: RootState, modules: ModuleTypes[]) =>
    modules.reduce(
      (reducedState, module) => ({
        ...reducedState,
        [module]: useSelector((state: RootState) => state[module]),
      }),
      state
    );
  const getters = new Proxy(rootGetters({} as RootState), {
    get: function (_target, module: ModuleTypes, _receiver) {
      let state: RootState = importModules({} as RootState, [module]);
      switch (module) {
        default:
          break;
      }
      return rootGetters(state)[module];
    },
  });

  const setters = rootActions(useDispatch() as any);
  const query: <
    TData = any,
    TVariables extends OperationVariables = OperationVariables
  >(
    options: QueryOptions<TVariables, TData>
  ) => Promise<ApolloQueryResult<TData>> = (options) =>
    dispatch(QUERY(options));
  const mutate: <
    T = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext
  >(
    options: MutationOptions<T, TVariables, TContext>
  ) => Promise<FetchResult<T, Record<string, any>, Record<string, any>>> = (
    options
  ) => dispatch(MUTATE(options));
  return [getters, setters, query, mutate] as [
    typeof getters,
    typeof setters,
    typeof query,
    typeof mutate
  ];
}
