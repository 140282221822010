import styled from "styled-components";

export const Footer = styled.footer`
  position: relative;
  display: flex;
  padding: 30px 5% 50px 5%;
  width: 90%;
  color: #364856;
  &::before {
    position: absolute;
    content: "";
    min-width: 90%;
    min-height: 1px;
    background-color: #a0a0a0;
    top: 0;
  }
`;

export const FooterList = styled.ul`
  list-style: none;
  padding: 0 5%;
  margin: 0;
`;

export const FooterListItem = styled.li`
  margin-bottom: 10px;
`;

export const FooterStyledLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.label`
  position: absolute;
  font-size: 11px;
  font-weight: 600;
  bottom: 50px;
  right: 10%;
`;
