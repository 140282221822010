import SVG from "../SVG";
import SolutionCard from "./SolutionCard";
import * as S from "./Styles";

const Solutions = () => {
  const solutions = [
    {
      title: "BIA - Business Inteligence Analytcs",
      image: <SVG.Image.Bia />,
      body: "BIA é a ferramenta que voce precisa para ter a maior assertividade nas analises da sua empresa, trazendo agilidade e pro atividade",
      link: "https://biaweb.app",
    },
    {
      title: "EASYSYNC - TOOLS",
      image: <SVG.Image.Tools />,
      body: "EASYSYNC - TOOLS é a solução que traz inovação em geração de cartazes para promoções e consulta de preços.",
      link: "https://tools.easysync.com.br",
    },
  ];

  return (
    <S.SolutionsWrapper id="solutions-section">
      <S.SolutionsHeader>
        <S.SolutionsTitle>Soluções</S.SolutionsTitle>
        <S.SolutionsSubtitle>
          CONHEÇA ALGUNS DOS NOSSOS SERVIÇOS
        </S.SolutionsSubtitle>
      </S.SolutionsHeader>
      <S.SolutionsBody>
        {solutions.map((solution, index) => (
          <SolutionCard key={index} {...solution} />
        ))}
      </S.SolutionsBody>
    </S.SolutionsWrapper>
  );
};

export default Solutions;
