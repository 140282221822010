import SVG from "../SVG";
import * as S from "./Styles";

const Customers = () => {
  return (
    <S.CustomersWrapper>
      <S.CustomersTitle>Nossos clientes.</S.CustomersTitle>
      <S.CustomersSubtitle>
        Construímos relações sólidas que agregam valor ao negócio e resultados
        que superam expectativas.
      </S.CustomersSubtitle>
      <S.CustomerBody>
        <S.CustomerImageContainer>
          <SVG.Image.Tropical />
        </S.CustomerImageContainer>
        <S.CustomerImageContainer>
          <SVG.Image.Telhacon />
        </S.CustomerImageContainer>
        <S.CustomerImageContainer>
          <SVG.Image.SaoJorge />
        </S.CustomerImageContainer>
      </S.CustomerBody>
    </S.CustomersWrapper>
  );
};

export default Customers;
