import { SVGProps } from "react";
import SVG from ".";
import { SVGImageBanner } from "./SVGImageBanner";
import { SVGImageAbout } from "./SVGImageAbout";
import { SVGImageTools } from "./SVGImageTools";
import { SVGImageBia } from "./SVGImageBia";
import { SVGImageTropical } from "./SVGImageTropical";
import { SVGImageTelhacon } from "./SVGImageTelhacon";
import { SVGImageSaoJorge } from "./SVGImageSaoJorge";

export default Object.assign(
  ({ children, className = "", ...rest }: SVGProps<any>) => {
    return (
      <SVG className={`svg-image ${className}`} {...rest}>
        {children}
      </SVG>
    );
  },
  {
    Banner: SVGImageBanner,
    About: SVGImageAbout,
    Tools: SVGImageTools,
    Bia: SVGImageBia,
    Tropical: SVGImageTropical,
    Telhacon: SVGImageTelhacon,
    SaoJorge: SVGImageSaoJorge,
  }
);
