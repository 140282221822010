import {
  ApolloClient,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  HttpLink,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const baseUrl = process.env.REACT_APP_BASE_URL;
const httpLink = new HttpLink({ uri: `${baseUrl}/graphql` });
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "apollo-require-preflight": "true",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const QUERY =
  <T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ) =>
  () =>
    new Promise<ApolloQueryResult<T>>((resolve, reject) => {
      apolloClient
        .query<T>({ ...options, fetchPolicy: "no-cache" })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });

export const MUTATE =
  <
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext
  >(
    options: MutationOptions<TData, TVariables, TContext>
  ) =>
  () =>
    new Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>(
      (resolve, reject) => {
        apolloClient
          .mutate<TData, TVariables, TContext>(options)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );

const graphqlActionCreators = (dispatch: <T>(options: any) => T) =>
  Object.assign(
    {},
    {
      QUERY: <
        T = any,
        TVariables extends OperationVariables = OperationVariables
      >(
        options: QueryOptions<TVariables, T>
      ) => dispatch<Promise<ApolloQueryResult<T>>>(QUERY(options)),
      MUTATE: <
        TData = any,
        TVariables extends OperationVariables = OperationVariables,
        TContext extends Record<string, any> = DefaultContext
      >(
        options: MutationOptions<TData, TVariables, TContext>
      ) =>
        dispatch<
          Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>
        >(MUTATE(options)),
    }
  );

export default graphqlActionCreators;
