const PageAppRedirect = () => {
  const handleRedirect = () => {
    window.location.href = "https://tools.easysync.com.br";
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <h1>Esta página mudou de endereço</h1>
      <p>A página que você está procurando foi movida para um novo endereço.</p>
      <button
        onClick={handleRedirect}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "10px",
          cursor: "pointer",
        }}
      >
        Ir para a nova página
      </button>
    </div>
  );
};

export default PageAppRedirect;
