import React from "react";
import * as S from "./Styles";
import SVG from "../SVG";

interface FormButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const FormButton = ({
  children,
  loading = false,
  disabled,
  ...rest
}: FormButtonProps) => {
  return (
    <S.FormButton disabled={loading ? true : disabled} {...rest}>
      {loading ? <SVG.Icon.Loader width={25} height={25} fill="white" /> : children}
    </S.FormButton>
  );
};

export default FormButton;
