import Modal from "..";
import Form from "../../Form";
import useStore from "../../Hooks/useStore";

const ContactSuccessModal = () => {
  const [getters, setters] = useStore();
  const {
    form: { getForm },
  } = getters;
  const {
    form: { setForm },
  } = setters;
  const isOpen = getForm("contactSuccessModal");
  const onClose = () => {
    setForm("contactSuccessModal", false);
  };
  return (
    <Modal
      style={{
        color: "#364856",
        background: "rgb(226 226 226)",
      }}
      isOpen={isOpen}
      onClose={onClose}
      height={"auto"}
      width={"40vw"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "2rem 0 ",
            fontSize: "20px",
          }}
        >
          Obrigado por entrar em contato! Em breve responderemos à sua
          solicitação.
        </div>
        <Form.Button
          style={{
            width: "105px",
          }}
          onClick={onClose}
        >
          OK
        </Form.Button>
      </div>
    </Modal>
  );
};

export default ContactSuccessModal;
