function removerZerosEsquerda(str: string) {
  if (str.length === 1 && str.charAt(0) === "0") {
    return str;
  }
  return str.replace(/^0+/, "");
}

export const maskNumber = (value: string) => {
  const result = value.replace(/\D+/g, "");
  return removerZerosEsquerda(result).slice(0, 5);
};

export const maskIntegerNumber = (value: string) => {
  const result = value.replace(/\D+/g, "");
  const trimmedValue = removerZerosEsquerda(result).slice(0, 5);
  return trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const maskPhone = (phoneNumber: string) => {
  return phoneNumber
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1 $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
};

export const unmaskPhone = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, "");
};

export function numbersMask(input: string | number) {
  const numeros = `${input}`.replace(/\D/g, "");

  return numeros;
}

export function formatBRL(value: string | number): string {
  let sign = "";
  if (typeof value === "number" && value < 0) {
    sign = "- ";
  }
  value = String(value).replace(/\D/g, ""); // Remove todos os caracteres exceto números

  const numericValue = parseInt(value);

  if (isNaN(numericValue) || numericValue === 0) {
    return "R$ 0,00";
  }

  // Divide por 100 para obter reais e centavos
  const formattedValue = (numericValue / 100).toFixed(2);

  // Separa reais e centavos
  // eslint-disable-next-line prefer-const
  let [reais, centavos] = formattedValue.split(".");

  // Adiciona pontos para separar milhares
  reais = parseInt(reais).toLocaleString("pt-BR");

  return sign + "R$ " + reais + "," + centavos;
}

export function unmaskCurrency(value: string, max?: number): string {
  const numericValue = +value.replace(/\D/g, "");
  if (max === 0) {
    return "0";
  }
  return `${max ? Math.min(numericValue, max) : numericValue}`;
}

export function formatCPF(cpf: string): string {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  return cpf;
}

export function unmaskCPF(value: string) {
  return value.replace(/\D/g, "").slice(0, 11);
}
export function maskCPF(value: string | null | undefined): string {
  if (!value) {
    return "";
  }
  const numericCPF = value.replace(/\D/g, "").slice(0, 11);
  let formattedCPF = "";
  for (let i = 0; i < numericCPF.length; i++) {
    if (i === 3 || i === 6) {
      formattedCPF += ".";
    } else if (i === 9) {
      formattedCPF += "-";
    }
    formattedCPF += numericCPF[i];
  }
  return formattedCPF;
}
