import * as S from "./Styles";
import FormInput from "./FormInput";
import FormContact from "./FormContact";
import FormTextArea from "./FormTextArea";
import FormButton from "./FormButton";

export interface FormProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {}

export default Object.assign(
  ({ children, ...rest }: FormProps) => {
    return <S.Form {...rest}>{children}</S.Form>;
  },
  {
    Input: FormInput,
    TextArea: FormTextArea,
    Contact: FormContact,
    Header: S.FormHeader,
    Title: S.FormTitle,
    Body: S.FormBody,
    Footer: S.FormFooter,
    Button: FormButton,
  }
);
