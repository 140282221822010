import * as S from "./Styles";

const Choose = () => {
  const choices = [
    {
      title: "Soluções Que Somam",
      body: "Temos soluções que te ajudam otimizar tempo aumentando o desempenho das suas analises, equipes e produtos.",
    },
    {
      title: "Gestão Descomplicada",
      body: "Nosso sistema de BI (BIA) foi desenvolvido com o proposito de descomplicar a gestão da sua empresa, com analises rápidas e precisas.",
    },
    {
      title: "Suporte Rápido e Preciso",
      body: "O suporte de nossas ferramentas é especializado para atender o usuário mais rápido possível e sanar quais quer duvidas.",
    },
    {
      title: "Soluções Abrangentes e Escalonáveis",
      body: "As soluções que desenvolvemos não são engessadas, fixas ou sem parâmetro para mudanças. Tem uma sugestão de mudança? Sempre vamos ouvi-la!",
    },
  ];
  return (
    <S.ChooseWrapper>
      <S.ChooseTitle>Porque nos escolher?</S.ChooseTitle>
      <S.ChooseBody>
        {choices.map(({ title, body }, index) => (
          <S.ChooseCard key={index}>
            <S.ChooseCardTitle>{title}</S.ChooseCardTitle>
            <S.ChooseCardBody>{body}</S.ChooseCardBody>
          </S.ChooseCard>
        ))}
      </S.ChooseBody>
    </S.ChooseWrapper>
  );
};

export default Choose;
