import { toSection } from "../../store/utils";
import useStore from "../Hooks/useStore";
import * as S from "./Styles";

const Footer = () => {
  const [, setters] = useStore();
  const {
    form: { setForm },
  } = setters;
  const openContactForm = () => {
    setForm("contactModal", true);
  };

  const openPrivacyPolicy = () => {
    setForm("privacyPolicyModal", true);
  };

  const items = [
    {
      label: "Contato",
      onClick: openContactForm,
    },
    {
      label: "Sobre",
      onClick: toSection("about"),
    },
    {
      label: "Soluções",
      onClick: toSection("solutions"),
    },
    {
      label: "Trabalhe conosco",
    },
    {
      label: "Política de Privacidade",
      onClick: openPrivacyPolicy,
    },
  ];
  return (
    <S.Footer>
      <S.FooterList>
        {items.map(({ label, onClick }, index) => (
          <S.FooterListItem key={index}>
            <S.FooterStyledLink onClick={onClick}>{label}</S.FooterStyledLink>
          </S.FooterListItem>
        ))}
      </S.FooterList>
      <S.Copyright>© Copyright 2020-2024 EasySync Technology</S.Copyright>
    </S.Footer>
  );
};

export default Footer;
