import Modal from "..";
import useStore from "../../Hooks/useStore";
import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  font-family: Arial, sans-serif;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2em;
  color: #333;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #555;
  margin-top: 20px;
`;

const Paragraph = styled.p`
  font-size: 1em;
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
  color: #666;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const PrivacyPolicyModal = () => {
  const [getters, setters] = useStore();
  const {
    form: { getForm },
  } = getters;
  const {
    form: { setForm },
  } = setters;
  const isOpen = getForm("privacyPolicyModal");
  const onClose = () => {
    setForm("privacyPolicyModal", false);
  };
  return (
    <Modal
      style={{
        color: "#364856",
        background: "rgb(226 226 226)",
      }}
      isOpen={isOpen}
      onClose={onClose}
      height={"auto"}
      width={"40vw"}
    >
      <Title>Política de Privacidade</Title>
      <Container>
        <SectionTitle>1. Introdução</SectionTitle>
        <Paragraph>
          Bem-vindo ao site EasySync. Esta Política de Privacidade descreve como
          coletamos, usamos, armazenamos e protegemos suas informações pessoais
          quando você visita nosso site e utiliza nossos serviços.
        </Paragraph>

        <SectionTitle>2. Informações que Coletamos</SectionTitle>
        <Paragraph>Informações de Contato:</Paragraph>
        <List>
          <ListItem>Nome</ListItem>
          <ListItem>Endereço de e-mail</ListItem>
          <ListItem>Número de telefone</ListItem>
        </List>
        <Paragraph>Informações de Uso:</Paragraph>
        <List>
          <ListItem>Dados sobre como você interage com nosso site</ListItem>
          <ListItem>Endereço IP</ListItem>
          <ListItem>
            Navegador, páginas visitadas e tempo de permanência
          </ListItem>
        </List>
        <Paragraph>Cookies e Tecnologias Similares:</Paragraph>
        <Paragraph>
          Utilizamos cookies para melhorar sua experiência de navegação e
          personalizar o conteúdo.
        </Paragraph>

        <SectionTitle>3. Uso das Informações</SectionTitle>
        <Paragraph>Utilizamos as informações coletadas para:</Paragraph>
        <List>
          <ListItem>Fornecer e melhorar nossos serviços</ListItem>
          <ListItem>Personalizar sua experiência no site</ListItem>
          <ListItem>
            Enviar comunicações relacionadas aos nossos produtos e serviços
          </ListItem>
          <ListItem>Cumprir obrigações legais e regulatórias</ListItem>
        </List>

        <SectionTitle>4. Compartilhamento de Informações</SectionTitle>
        <Paragraph>
          Não vendemos, trocamos ou transferimos suas informações pessoais para
          terceiros, exceto quando necessário para cumprir com a lei ou proteger
          nossos direitos.
        </Paragraph>

        <SectionTitle>5. Segurança das Informações</SectionTitle>
        <Paragraph>
          Adotamos medidas de segurança adequadas para proteger suas informações
          contra acesso não autorizado, alteração, divulgação ou destruição.
        </Paragraph>

        <SectionTitle>6. Seus Direitos</SectionTitle>
        <Paragraph>
          Você tem o direito de acessar, corrigir, atualizar ou solicitar a
          exclusão de suas informações pessoais. Para exercer esses direitos,
          entre em contato conosco pelo e-mail contato@easysync.com.br.
        </Paragraph>

        <SectionTitle>7. Alterações na Política de Privacidade</SectionTitle>
        <Paragraph>
          Podemos atualizar esta Política de Privacidade periodicamente.
          Notificaremos sobre quaisquer mudanças significativas através de um
          aviso em nosso site.
        </Paragraph>

        <SectionTitle>8. Contato</SectionTitle>
        <Paragraph>
          Se tiver dúvidas sobre esta Política de Privacidade, entre em contato
          conosco pelo e-mail contato@easysync.com.br.
        </Paragraph>
      </Container>
    </Modal>
  );
};

export default PrivacyPolicyModal;
