import { css, styled } from "styled-components";
import { CSSProperties } from "react";
import { InputWrapperProps } from "./FormInput";

export const Form = styled.form``;
export const FormHeader = styled.header``;
export const FormTitle = styled.h1``;
export const FormBody = styled.div``;
export const FormFooter = styled.footer``;

export const FormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  min-width: 105px;
  min-height: 45px;
  background: #5e4292;
  color: white;
  font-weight: 600;
  &:hover {
    background: #513389;
  }
  &:active {
    background: #492e7c;
  }
`;

export const FormContactInputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 15px;
  @media (max-width: 645px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreaLabel = styled.label<{
  $transitionTime?: number;
  $hasError?: boolean;
}>`
  ${({$hasError = false, $transitionTime = 200}) => css`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${$hasError ? "red" : "#364856"};
    transition: color ${$transitionTime * 2}ms;
  `}
`;

export const TextArea = styled.textarea`
  width: calc(100% - 20px);
  height: 25vh;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 10px;
`;

export const AppWrapper = styled.div`
  display: block;
`;

export const Field = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.label<InputWrapperProps>`
  ${({
    $fontSize,
    $isFocused,
    $inputPaddingLeft,
    $labelScale,
    $inputHeight,
    $transitionTime,
    $hasError,
    $fontColor,
    $activeLabelColor,
  }) => {
    return css`
      position: absolute;
      display: flex;
      align-items: center;
      transition: transform ${$transitionTime}ms, color ${$transitionTime}ms;
      transform-origin: 0 0;
      transform: ${!$isFocused
        ? `matrix(1, 0, 0, 1, ${$inputPaddingLeft}, 0)`
        : `matrix(${$labelScale}, 0, 0, 
        ${$labelScale}, 0, -${$inputHeight / 2 + 10})`};
      cursor: text;
      color: ${$hasError ? "red" : $isFocused ? $activeLabelColor : $fontColor};
      height: 100%;
      z-index: 1;
      font-size: ${$fontSize}px;
      font-weight: 600;
    `;
  }}
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ $borderRadius, $backgroundColor }) => css`
    display: flex;
    position: relative;
    background: ${$backgroundColor};
    border-radius: ${$borderRadius}px;
    align-items: center;
  `}
`;

export const Input = styled.input<InputWrapperProps>`
  ${({ $borderRadius, $fontSize, $fontColor, $hasError }) => css`
    display: flex;
    border: none;
    outline: none;
    font-size: ${$fontSize}px;
    background: White;
    height: 56px;
    padding: 0 0 0 20px;
    width: 100%;
    z-index: 0;
    border-radius: ${$borderRadius}px;
    color: ${$hasError ? "red" : $fontColor};
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  `}
`;

export const Error = styled.p<InputWrapperProps>`
  ${({ $hasError, $transitionTime }) => css`
    transition: color ${$transitionTime * 2}ms;
    color: ${$hasError ? "red" : "transparent"};
    min-height: 18.4px;
    text-align: start;
    margin: 10px 0;
  `}
`;

export const Message = styled.p<{
  $transitionTime?: number;
  $hasError?: boolean;
}>`
  ${({ $hasError = false, $transitionTime = 200 }) => css`
    transition: color ${$transitionTime * 2}ms;
    color: ${$hasError ? "red" : "transparent"};
    min-height: 18.4px;
    text-align: start;
    margin: 10px 0;
  `}
`;

export const Mark = styled.div<{
  top: CSSProperties["top"];
  left: CSSProperties["left"];
}>`
  ${({ top, left }) => css`
    position: absolute;
    top: ${top}px;
    left: ${left}px;
    z-index: 10;
  `}
`;
