import { toSection } from "../../store/utils";
import useStore from "../Hooks/useStore";
import * as S from "./Styles";

const Header = () => {
  const [, setters] = useStore();
  const {
    form: { setForm },
  } = setters;

  const openContactForm = () => {
    setForm("contactModal", true);
  };

  return (
    <S.HeaderWrapper>
      <S.Logo />
      <S.HeaderButtonsContainer>
        <S.HeaderButton>Home</S.HeaderButton>
        <S.HeaderButton onClick={toSection("about")}>Sobre</S.HeaderButton>
        <S.HeaderButton onClick={toSection("solutions")}>
          Soluções
        </S.HeaderButton>
        <S.HeaderButton onClick={openContactForm}>Contato</S.HeaderButton>
      </S.HeaderButtonsContainer>
    </S.HeaderWrapper>
  );
};

export default Header;
