import * as S from "./Styles";
import SVG from "../SVG";

const About = () => {
  return (
    <S.ContainerAbout id="about-section">
      <S.AboutImg>
        <SVG.Image.About />
      </S.AboutImg>
      <S.About>
        <S.AboutTitle>Sobre Nós</S.AboutTitle>
        <S.AboutBody>
          <S.AboutParagraph>
            A EasySync nasceu da vontade de tornar o mundo mais conectado, ágil,
            preciso e otimizado!
          </S.AboutParagraph>
          <S.AboutParagraph>
            Desde 2020 viemos inovando e trazendo praticidade para nossos
            clientes através de nossas soluções que são de fácil entendimento
            para os usuários e completamente robusta em informações.
          </S.AboutParagraph>
          <S.AboutParagraph>
            EasySync tem como objetivo principal desde sempre, tornar o as
            coisas mais fáceis e rápidas.
          </S.AboutParagraph>
        </S.AboutBody>
      </S.About>
    </S.ContainerAbout>
  );
};
export default About;
