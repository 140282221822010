import { InputHTMLAttributes } from "react";
import * as S from "./Styles";

interface FormTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  errorMessage?: boolean;
}
const FormTextArea = ({
  label,
  errorMessage = true,
  error,
  ...rest
}: FormTextAreaProps) => {
  const hasError = !!error;
  return (
    <S.TextAreaContainer>
      <S.TextAreaLabel $hasError={hasError}>{label}</S.TextAreaLabel>
      <S.TextArea {...rest} />
      {errorMessage && <S.Message $hasError={hasError}>{error}</S.Message>}
    </S.TextAreaContainer>
  );
};

export default FormTextArea;
