import styled from "styled-components";

export const BannerWrapper = styled.div`
  position: relative;
  background-color: transparent;
  max-width: 100vw;
  max-height: 30vw;
  overflow: hidden;
  border: none;
`;

export const BannerIFrame = styled.iframe`
  background-color: black;
  width: 100vw;
  height: 35vw;
  transform: scale(1.6);
  transform-origin: center center; 
`;

export const BannerTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: white;
  font-size: 5vw;
  text-align: center;
  cursor: default;
  font-weight: bold;
  user-select: none;
`;
