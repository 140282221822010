import React, { CSSProperties, ReactNode, useRef } from "react";
import * as S from "./styles";
import SVG from "../SVG";
import ContactFormModal from "./PrivacyPolicyModal";
import ContactSuccessModal from "./ContactFormModal/ContactSuccessModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  headerTitle?: string;
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  style: CSSProperties;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  height,
  width,
  headerTitle = "",
  style,
}: ModalProps) => {
  const modalRef = useRef(null);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClose && modalRef.current === e.target) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <S.ModalOverlay ref={modalRef} onMouseDown={handleOverlayClick}>
      <S.ModalContent height={height} width={width} style={style}>
        {onClose ? (
          <S.ModalCloseButton onClick={onClose}>
            <SVG.Icon.Close />
          </S.ModalCloseButton>
        ) : null}
        {headerTitle ? (
          <S.ModalHeader>
            <S.ModalTitle>{headerTitle}</S.ModalTitle>
          </S.ModalHeader>
        ) : null}
        {children}
      </S.ModalContent>
    </S.ModalOverlay>
  );
};

export default Object.assign(Modal, {
  ContactForm: ContactFormModal,
  ContactSuccess: ContactSuccessModal,
  PrivacyPolicy: PrivacyPolicyModal,
});
