import styled from "styled-components";

export const ContainerAbout = styled.div`
  display: flex;
  margin: 95px 0 0 10%;
  width: 75%;
  color: #364856;
  gap: 50px;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
    margin: 95px 5% 0 5%;
    width: 90%;
  }
`;

export const AboutImg = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 37vw;
    height: auto;
    @media (max-width: 1200px) {
      width: 50vw;
    }
  }
`;

export const About = styled.div``;

export const AboutTitle = styled.h1`
  display: flex;
  font-size: 65px;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
`;

export const AboutBody = styled.p`
  font-size: 20px;
`;

export const AboutParagraph = styled.p`
  margin-block-start: 0;
  margin-block-end: 10px;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;
