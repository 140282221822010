export function phone(phoneNumber: string) {
  const phone = phoneNumber.replace(/\D/g, '')
  if (phone.length !== 11) {
    return false
  }
  if (phone[2] !== '9') {
    return false
  }
  return true
}

export function required(value: string) {
  return !!value
}

export function minLength(value: string, length: number) {
  return value.length >= length
}

export function nonZero(value: string | number) {
  return +value !== 0
}

export function email(value: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export function CPF(cpf: string) {
  cpf = cpf.replace(/[^\d]/g, '')
  if (cpf.length !== 11) {
    return false
  }
  if (/^(\d)\1+$/.test(cpf)) {
    return false
  }
  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let digit = 11 - (sum % 11)
  if (digit >= 10) {
    digit = 0
  }
  if (parseInt(cpf.charAt(9)) !== digit) {
    return false
  }
  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  digit = 11 - (sum % 11)
  if (digit >= 10) {
    digit = 0
  }
  if (parseInt(cpf.charAt(10)) !== digit) {
    return false
  }
  return true
}
