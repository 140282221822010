import { SVGProps } from "react";
import SVG from ".";
import { SVGIconLogo } from "./SVGIconLogo";
import { SVGIconClose } from "./SVGIconClose";
import { SVGIconLoader } from "./SVGIconLoader";

export default Object.assign(
  ({ children, className = "", ...rest }: SVGProps<any>) => {
    return (
      <SVG className={`svg-icon ${className}`} {...rest}>
        {children}
      </SVG>
    );
  },
  {
    Logo: SVGIconLogo,
    Close: SVGIconClose,
    Loader: SVGIconLoader,
  }
);
