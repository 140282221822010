import styled from "styled-components";

export const ChooseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #364856;
  margin: 0 5% 100px 5%;
`;

export const ChooseTitle = styled.h1`
  font-size: 65px;
  margin-block-end: 50px;
`;

export const ChooseBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ChooseCard = styled.div`
  background: #f3f5f8;
  border-radius: 25px;
  text-align: center;
`;

export const ChooseCardTitle = styled.h2`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin-block-start: 0;
  margin-block-end: 0;
  min-height: 140px;
  margin: auto;
  padding: 50px 90px 0 90px;
  &::before {
    position: absolute;
    content: "";
    min-width: 30px;
    min-height: 3px;
    background-color: #c8d5dc;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ChooseCardBody = styled.div`
  position: relative;
  font-size: 16px;
  margin: 30px 50px 70px 50px;
`;
