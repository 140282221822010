import { SVGProps } from "react";
import SVGICon from "./SVGIcon";
import SVGImage from "./SVGImage";

export default Object.assign(
  ({ children, className = "", ...rest }: SVGProps<any>) => {
    return (
      <svg
        className={`svg ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...rest}
      >
        {children}
      </svg>
    );
  },
  {
    Icon: SVGICon,
    Image: SVGImage,
  }
);
