import About from "./components/About";
import Banner from "./components/Banner";
import Choose from "./components/Choose";
import Customers from "./components/Customers";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Modal from "./components/Modal";
import Pages from "./components/Pages";
import Solutions from "./components/Solutions";
import { RouteObject, useRoutes } from "react-router-dom";
const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <>
            <Header />
            <Banner />
            <About />
            <Solutions />
            <Customers />
            <Choose />
            <Footer />
          </>
        ),
      },
      {
        path: "app",
        element: <Pages.AppRedirect />,
      },
    ],
  },
];
function App() {
  let pages = useRoutes(routes);

  return <div className="App">{pages}</div>;
}

export default App;
