import * as S from "./Styles";
import Form from ".";
import useStore from "../Hooks/useStore";
import { maskPhone, unmaskPhone } from "../../store/mask";
import React from "react";

const FormContact = () => {
  const [getters, setters] = useStore();
  const {
    form: { getForm, getContactFormMessage },
  } = getters;
  const {
    form: { setForm, validateContactForm, createContact },
  } = setters;

  const getField = (field: keyof ContactForm) =>
    getForm(`contact.data.${field}`);

  const setField =
    (field: keyof ContactForm) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm(`contact.data.${field}`, e.target.value);
    };

  const blurField = (field: keyof ContactForm) => () => {
    setForm(`contact.blur.${field}`, true);
  };

  const messages = getContactFormMessage();

  const control = (field: keyof ContactForm) => ({
    value: getField(field),
    onChange: setField(field),
    onBlur: blurField(field),
    error: messages[field],
  });

  const clearForm = () => {
    setForm("contact", {
      data: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      blur: {
        name: false,
        email: false,
        phone: false,
        subject: false,
        message: false,
      },
    });
  };

  const [loading, setLoading] = React.useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validateContactForm();
    if (isValid) {
      setLoading(true);
      createContact()
        .then(() => {
          clearForm();
          setForm("contactModal", false);
          setForm("contactSuccessModal", true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Header>
        <Form.Title
          style={{
            display: "flex",
            justifyContent: "center",
            marginBlockStart: "0.5em",
            marginBlockEnd: "2em",
          }}
        >
          Contate-nos
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <S.FormContactInputsContainer>
          <Form.Input label="Nome" {...control("name")} />
          <Form.Input label="E-Mail" {...control("email")} />
          <Form.Input
            label="Telefone"
            mask={maskPhone}
            unmask={unmaskPhone}
            {...control("phone")}
          />
          <Form.Input label="Assunto" {...control("subject")} />
        </S.FormContactInputsContainer>
        <Form.TextArea label="Mensagem" {...control("message")} />
        <Form.Footer
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Form.Button type="submit" loading={loading}>
            Enviar
          </Form.Button>
        </Form.Footer>
      </Form.Body>
    </Form>
  );
};

export default FormContact;
