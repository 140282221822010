import styled from "styled-components";
import SVG from "../SVG";

export const HeaderWrapper = styled.header`
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  padding: 10px;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
`;
export const HeaderButton = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 1rem;
  font-size: 15px;
  font-weight: 600;
  margin: 0 0.25rem;
  background-color: transparent;
  &:hover {
    background-color: #efefef;
  }
  &:active {
    background-color: #dddddd;
  }
`;

export const Logo = styled(SVG.Icon.Logo)``;
