import formActions from "./form/action";
import formActionCreators from "./form/actionCreators";
import graphqlActionCreators from "./graphql/actionCreators";
const rootSetters = (dispatch: (action: any) => any) =>
  Object.assign(
    {},
    {
      form: {
        ...formActions(dispatch),
        ...formActionCreators(dispatch),
      },
      graphql: {
        ...graphqlActionCreators(dispatch),
      },
    }
  );

export default rootSetters;
