import styled from "styled-components";

export const SolutionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
  color: #364856;
  margin-top: 100px;
`;

export const SolutionsHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const SolutionsTitle = styled.h1`
  font-size: 65px;
  margin-block-end: 0;
`;

export const SolutionsSubtitle = styled.h2`
  font-size: 22px;
`;

export const SolutionsBody = styled.div`
  display: flex;
  margin: 100px 5% 100px;
  width: 85%;
  gap: 5%;
`;

export const SolutionCard = styled.div`
  color: #364856;
`;

export const SolutionCardImageContainer = styled.div`
  cursor: pointer;
  svg {
    max-width: 37vw;
    height: auto;
  }
`;

export const SolutionCardTitle = styled.h1`
  font-size: 22px;
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
`;

export const SolutionCardBody = styled.div`
  font-size: 16px;
`;
