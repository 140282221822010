import { Component, SVGProps } from "react";
import SVG from "..";
interface SVGIconCloseProps extends SVGProps<any> {}
export class SVGIconClose extends Component<SVGIconCloseProps> {
  render() {
    const { width = 40, height = 40, fill = "#513389", ...rest } = this.props;
    return (
      <SVG
        className="svg-icon-close"
        width={`${width}`}
        height={`${height}`}
        viewBox={`0 0 ${width} ${height}`}
        {...rest}
      >
        <path
          d="M557.2 512l136.4-136.4c12.4-12.4 12.4-32.8 0-45.2-12.4-12.4-32.8-12.4-45.2 0L512 466.8l-136.4-136.4c-12.4-12.4-32.8-12.4-45.2 0-6.2 6.2-9.4 14.4-9.4 22.6 0 8.2 3.2 16.4 9.4 22.6l136.4 136.4-136.4 136.4c-6.2 6.2-9.4 14.4-9.4 22.6 0 8.2 3.2 16.4 9.4 22.6 12.4 12.4 32.8 12.4 45.2 0l136.4-136.4 136.4 136.4c12.4 12.4 32.8 12.4 45.2 0 12.4-12.4 12.4-32.8 0-45.2L557.2 512z"
          fill={fill}
          transform="scale(0.0390625)"
        />
      </SVG>
    );
  }
}
