import { RootState } from "..";
import { getNestedField } from "../utils";
import { email, phone, required } from "../validations";

export const getForm =
  (state: RootState) =>
  <T extends FlattenKeys<FormState>>(field: T) =>
    getNestedField(state.form, field);

export const getContactFormMessage = (state: RootState) => () => {
  const { data, blur } = state.form.contact;

  const messages: Record<keyof ContactForm, string> = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  messages.name = blur.name && !required(data.name) ? "Informe o nome" : "";

  messages.email =
    blur.email && !required(data.email)
      ? "Informe o email"
      : blur.email && !email(data.email)
      ? "Informe um email válido"
      : "";

  messages.phone =
    blur.phone && !required(data.phone)
      ? "Informe o telefone"
      : blur.phone && !phone(data.phone)
      ? "Informe um telefone válido"
      : "";

  messages.subject =
    blur.subject && !required(data.subject) ? "Informe o assunto" : "";

  messages.message =
    blur.message && !required(data.message) ? "Informe a mensagem" : "";

  return messages;
};

const formGetters = (state: RootState) => ({
  getForm: getForm(state),
  getContactFormMessage: getContactFormMessage(state),
});

export default formGetters;
