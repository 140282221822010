import * as S from "./Styles";
interface BannerProps {
  title?: string;
}
const Banner = ({ title = "EasySync Technology" }: BannerProps) => {
  return (
    <S.BannerWrapper>
      <S.BannerIFrame
        src="https://www.youtube.com/embed/9HaU8NjH7bI?autoplay=1&loop=1&mute=1&controls=0&modestbranding=1&showinfo=0&disablekb=1&loop=1&playlist=9HaU8NjH7bI"
        allow="accelerometer; autoplay *; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></S.BannerIFrame>
      <S.BannerTitle>{title}</S.BannerTitle>
    </S.BannerWrapper>
  );
};

export default Banner;
